<template>
  <tml-popup
    name="added-to-cart-popup"
    title="Added to bag"
    no-fill-mobile
    :icon="faCheck"
  >
    <template #content>
      <p v-if="!hideReturnsPolicy">
        <b>Easy Returns and Exchanges</b>
        - instructions on the packaging for returns or exchanges.
      </p>
      <tml-reassurance
        class="mt-4 md:block hidden"
        :hide-returns-policy="hideReturnsPolicy"
      />
    </template>
    <template #footer>
      <div class="row">
        <div class="col-md-5 col-sm-6">
          <tml-button
            class="w-full mt-4"
            primary
            :text="continueText"
            @click="
              $store.dispatch('overlayElements/close', 'added-to-cart-popup')
            "
          />
        </div>
        <div class="col-md-5 col-sm-6 offset-md-2">
          <tml-button class="w-full mt-4" href="/basket/" text="View Bag" />
        </div>
      </div>
    </template>
  </tml-popup>
</template>

<script>
import {faCheck} from '@fortawesome/pro-regular-svg-icons';

export default {
  name: 'AddToCartPopup',

  props: {
    product: Object,
    hideReturnsPolicy: Boolean,
    continueText: {
      type: String,
      default: 'Continue Shopping',
    },
  },

  data() {
    return {
      faCheck,
    };
  },
};
</script>
